<template>
  <div>
    <el-form :inline="true" :model="dialogVisibleConditionForm">
      <el-form-item label="计划名称" class="formItemBoxStyle">
        <el-input
          @change="getSchoolPlanListFunc"
          v-model="dialogVisibleConditionForm.crsExpPlanName"
        ></el-input>
      </el-form-item>
      <el-form-item label="年级" class="formItemBoxStyle">
        <el-cascader
          @change="changeSysOrgSchoolGradeId"
          v-model="sysOrgSchoolGradeIdArr"
          :options="$store.state.public_data.sysSemesterTermGradeDicList"
          :props="{
            children: 'children',
          }"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button @click="getSchoolPlanListFunc" type="primary"
          >查询</el-button
        >
        <el-button @click="dialogVisibleResetBtn">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="dialogVisibleTableLoading"
      border
      ref="dialogVisibleTableData"
      :data="dialogVisibleTableData"
      tooltip-effect="dark"
      :header-cell-style="{
        'text-align': 'center',
        backgroundColor: '#dfe6ec',
      }"
      :cell-style="{ 'text-align': 'center' }"
      style="width: 100%"
      height="700"
    >
      <el-table-column
        prop="crsExpPlanName"
        label="教学计划名称"
      ></el-table-column>
      <el-table-column
        prop="sysSchoolClassGradeName"
        width="180"
        label="所属年级"
      ></el-table-column>
      <el-table-column
        prop="totalOpenLessonRate"
        width="180"
        label="所开出率"
      ></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="dialogVisibleViewBtn(scope.row)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getSchoolPlanList } from '@/api/courseStart/startClass.js'
export default {
  name: 'startClassPlanView',
  data() {
    return {
      sysOrgSchoolName: '',
      sysOrgSchoolId: '',
      // 查询学校开课计划弹框
      // dialogVisible: false,
      dialogVisibleTableLoading: false,
      dialogVisibleTableData: [],
      dialogVisibleConditionForm: {
        crsExpPlanName: '',
        sysOrgSchoolGradeId: 0,
        sysOrgSchoolId: 0,
        sysSchoolTermId: 0,
      },
      sysOrgSchoolGradeIdArr: [],
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
  },
  mounted() {
    this.dialogVisibleTableData = []
    this.sysOrgSchoolGradeIdArr = []
    this.sysOrgSchoolName = this.$route.query.sysOrgSchoolName
    this.sysOrgSchoolId = Number(this.$route.query.sysOrgSchoolId)
    this.dialogVisibleConditionForm.sysOrgSchoolId = Number(
      this.$route.query.sysOrgSchoolId
    )
    this.getSchoolPlanListFunc()
  },
  methods: {
    // 年级处理
    changeSysOrgSchoolGradeId(val) {
      if (val.length !== 0) {
        this.dialogVisibleConditionForm.sysOrgSchoolGradeId = val[1]
      } else {
        this.dialogVisibleConditionForm.sysOrgSchoolGradeId = ''
      }
      this.getSchoolPlanListFunc()
    },
    // 重置
    dialogVisibleResetBtn() {
      this.dialogVisibleConditionForm.crsExpPlanName = ''
      this.dialogVisibleConditionForm.sysOrgSchoolGradeId = ''
      this.sysOrgSchoolGradeIdArr = []
      this.getSchoolPlanListFunc()
    },
    async getSchoolPlanListFunc() {
      this.dialogVisibleTableLoading = true
      let conditionObj = JSON.parse(
        JSON.stringify(this.dialogVisibleConditionForm)
      )
      // 条件修改
      for (let key in conditionObj) {
        if (
          conditionObj[key] == '' ||
          conditionObj[key] == undefined ||
          conditionObj[key] == 0
        ) {
          delete conditionObj[key]
        }
      }
      await getSchoolPlanList(conditionObj)
        .then((res) => {
          this.dialogVisibleTableLoading = false
          if (res.success) {
            this.dialogVisibleTableData = res.data
          } else {
            this.$message.info(res.msg)
          }
        })
        .catch((err) => {})
    },
    dialogVisibleViewBtn(row) {
      this.$router.push({
        path: '/courseStart/startClassView',
        query: {
          sysOrgSchoolId: this.sysOrgSchoolId,
          sysOrgSchoolClassId: row.sysOrgSchoolClassId,
          sysOrgSchoolName: this.sysOrgSchoolName,
          sysSchoolTermId: row.sysOrgSchoolName,
          crsOpenPlanId: row.crsOpenPlanId,
        },
      })
    },
  },
  watch: {},
  filters: {
    tenantStatusFilters(key) {
      switch (key) {
        case 104:
          return `租户未被授权`
        case 102:
          return `租户授权过期`
        case 1:
          return `正常使用中`
        case 0:
          return `租户被禁用`
        default:
          return `状态码${key}`
      }
    },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY-MM-DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.margin10 {
  margin: 10px 0 20px 0;
}
.previewTopList {
  display: flex;
  margin-left: 30px;
  margin-bottom: 20px;
}
.previewLi {
  margin-right: 50px;
  .top {
    color: #929292;
    font-size: 16px;
    i {
      cursor: pointer;
    }
  }
  .bottom {
    font-size: 30px;
    span {
      font-size: 16px;
    }
  }
}
</style>
